<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2" class="d-flex justify-center">
        <v-btn text rounded @click="back" class="back">
          <v-icon color="primary">chevron_left</v-icon>
          <span class="primary--text">{{ $t('authorizationDetail.buttons.exit') }}</span>
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto" class="mb-5 pl-1" align-self="center">
        <p class="text-h3 pb-0 mb-0">
          {{ $t('authorizationDetail.authorizationLabel') }}
          <span class="primary--text">{{ this.authorizationCode }}</span>
        </p>
        <span class="text-body-2 pt-3 osg-gray-3--text font-weight-bold">
          {{ $t('authorizationDetail.createdDateLabel') }}
          <span class="font-weight-regular">{{ createdAt }}</span>
        </span>
      </v-col>
      <v-row dense>
        <v-col cols="auto" md="auto" class="pl-5 pt-5">
          <v-chip  class="osg-gray-comment">
            <span class="text-body-2 osg-gray-1--text font-weight-bold">
              {{ status }}
            </span>
          </v-chip>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'

const authorizationModule = createNamespacedHelpers('authorizationV2')

export default {
  name: 'DetailHeader',
  props: {
    authorizationCode: {
      type: String,
      required: true
    },

    createdAt: {
      type: String,
      required: false
    },

    status: {
      type: String,
      required: false
    }
  },
  methods: {
    ...authorizationModule.mapActions(['setSingleAuthorization']),
    back() {
      this.setSingleAuthorization({})
      this.$router.push({ name: 'HomeV2' })
    }
  },
  computed: {
    createdAtValue() {
      return this.createdAt ? moment(this.createdAt).format('DD/MM/YYYY HH:mm:ss') : '--'
    }
  }
}
</script>
<style scoped>
back {
  background-color: #fafafa;
}
</style>
