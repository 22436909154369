<template>
  <v-col :class="fieldCssClass">
    <template v-if="type === 'SECTION_TITLE'">
      <span v-bind="field.props">{{ field.label }}</span>
    </template>

    <template v-if="type === 'SEARCH_BENEFICIARY_WITH_EXTERNAL_BENEFITS'">
      <HorizontalCard :items="findJsonValue" :coverageType="coverageType" />
    </template>

    <template v-if="type === 'TEXT' || type === 'SPONSOR_PROVIDERS_DROPDOWN' || type === 'EMAIL'">
      <p v-bind="field.props">{{ field.label }}</p>
      <p class="text-body-2" v-if="findValue">{{ findValue }}</p>
      <p class="osg-gray--text text-body-3" v-else>{{ emptyListText }}</p>
    </template>

    <template v-if="type === 'DIAGNOSES' || type === 'DIFFERENTIAL_DIAGNOSES'">
      <p v-bind="field.props">{{ field.label }}</p>
      <div v-if="findJsonValue">
        <p class="text-body-2" v-for="diagnosis in findJsonValue" :key="diagnosis.code">
          {{ `(${diagnosis.code}) ${diagnosis.name}` }}
        </p>
      </div>
      <div v-else>
        <p class="osg-gray--text text-body-3">{{ emptyListText }}</p>
      </div>
    </template>

    <template v-if="type === 'DIAGNOSES_PRODUCT'" && source>
      <p v-bind="field.props">{{ field.label }}</p>
      <AuthorizationItem :authorizationItems="itemsBySource" :type="propType" />
    </template>

    <template v-if="type === 'TEXTAREA'">
      <p v-bind="field.props">{{ field.label }}</p>
      <p class="textarea-field text-body-2" v-if="findValue">{{ findValue }}</p>
      <p class="osg-gray--text text-body-3" v-else>{{ emptyListText }}</p>
    </template>

    <template v-if="type === 'DASHED_DIVIDER'">
      <v-divider class="osg-gray-6 dashed-divider"></v-divider>
    </template>

    <template v-if="type === 'FILE_UPLOAD' && fileList">
      <DisplayFiles :files="getFormattedFile(fileList)" source="DETAIL" :show-preview="true" :required="field.required" />
    </template>
  </v-col>
</template>

<script>
import HorizontalCard from './componets/HorizontalCard.vue'
import DisplayFiles from '@/components/v2/files/DisplayFiles.vue'

export default {
  name: 'DynamicComponent',
  components: {
    HorizontalCard,
    DisplayFiles
  },

  methods: {
    findField() {
      return this.values?.find(value => value.fieldType === this.type)
    },

    findFile() {
      return this.files?.find(file => file.fieldSlug === this.field.slug)
    },

    getFormattedFile(file) {
      return [
        {
          ...file,
          name: file.originalFileName,
          type: file.extension
        }
      ]
    }
  },

  props: {
    field: {
      type: Object,
      required: true
    },

    values: {
      type: [Object, String, Array] || null,
      required: false
    },

    coverageType: {
      type: String,
      required: false
    },

    files: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      title: 'Dynamic Component',
      description: 'This is a dynamic component template.'
    }
  },

  computed: {
    type() {
      return this?.field?.type || ''
    },

    findValue() {
      const field = this.findField()

      return field ? field.value : null
    },

    cardItems() {
      return this.values
    },

    findJsonValue() {
      const field = this.findField()

      if (!field) {
        return null
      }

      return JSON.parse(field.value)
    },

    emptyListText() {
      return this.$t('authorizationDetail.emptyList')
    },

    fileList() {
      return this.findFile()
    },

    fieldCssClass() {
      if (this.field.additionalCssClass) {
        return this.field.additionalCssClass
      }else{
        return this.field.cssClass || 'col-12'
      } 
    }
  }
}
</script>

<style scoped>
.textarea-field {
  border: 0.5px solid var(--v-osg-gray-2-base);
  border-radius: 10px;
  padding: 20px 16px;
}

.dashed-divider {
  border-top: 2.5px dashed var(--v-osg-gray-2-base);
}
</style>
