<template>
  <v-row dense :class="classes">
    <v-col cols="12">
      <v-row align="center" class="px-16">
        <v-col v-for="(item, index) in itemsByType" :key="index" cols="3">
          <p class="text-body-2 font-weight-bold osg-gray-1--text ma-0">{{ item.text }}</p>
          <p class="text-body-2 osg-gray-3--text ma-0 pt-1">{{ item.value }}</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HorizontalCard',

  props: {
    color: {
      type: String,
      default: 'primary-color'
    },

    items: {
      type: Object,
      default: null
    },

    coverageType: {
      type: String,
      default: ''
    }
  },

  computed: {
    classes() {
      return {
        'pa-3': true,
        'rounded': false,
        [`${this.color}`]: true,
        'custom-border': true
      }
    },
    itemsByType() {
      return [
        { text: this.$t('authorizationDetail.beneficiaryCard.coverage'), value: this.getTextCoverageType },
        { text: this.$t('authorizationDetail.beneficiaryCard.beneficiary'), value: this.items.name },
        { text: this.$t('authorizationDetail.beneficiaryCard.identification'), value: this.items.identityDocumentId },
        { text: this.$t('authorizationDetail.beneficiaryCard.sponsor'), value: this.getTextSponsor }
      ]
    },
    getTextCoverageType() {
      switch(this.coverageType) {
        case 'HOSPITALIZATION':
        default:
          return this.$t(`authorizationDetail.coverageType.hospitalization`)
      }
    },
    getTextSponsor() {
      const sponsorSlug = this.items.sponsorSlug
      switch(sponsorSlug) {
        case 'mx-mutuus':
        default:
          return this.$t(`authorizationDetail.sponsors.mutuus`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .primary-color {
    background-color: #e4f0ff;
  }
  .custom-border {
    border-radius: 20px;
  }
</style>